/* Templates */
.template-wrapper { 
  .text-wrapper {
    > *{
      &:first-child {
        margin-top: 0!important; 
      }
    }
  }
  .image-floated-left {
    overflow: auto; 
    img {
      float: left;
      max-width: 50%;
      margin: 0 20px 20px 0; 
      border-radius: 0; /* overwrite any styles in clients' bespoke.scss which set a border radius */
    }
    .text-wrapper {
      margin-bottom: 20px;
    }
  }
  .image-floated-right {
    overflow: auto;
    img {
      float: right;
      max-width: 50%;
      margin: 0 0 20px 20px;
      border-radius: 0; /* overwrite any styles in clients' bespoke.scss which set a border radius */
    }
    .text-wrapper {
      margin-bottom: 20px;
    }

  }
  .card-deck {
    display: flex;
    flex-wrap: nowrap;
    @media(min-width: 768px) {
      gap: 40px;
    }
    margin-bottom: 40px;
    @media(max-width: 768px) {
      flex-direction: column;
      flex-wrap: nowrap;
    }
    // &.breakpoint-lg { /* cannot reintroduce as clients didn't like so we removed CSS but the class will remain in the original html templates (removed from later versions of templates) */
    //   @media(max-width: 980px) { /* Approx width of TinyMCE content editor so that shows 'desktop' layout */
    //     flex-direction: column;
    //     flex-wrap: nowrap;
    //   }
    // }
  }
  .card { 
    background-color: white;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
    @media(max-width: 768px) {
      margin-bottom: 40px;
    }
    .text-wrapper {
      padding: 20px;
      h1, h2, h3, h4, h5, h6 {
        margin-bottom: .5em;
      }
      p {
        margin-bottom: .5em;
      }
      &.contains-footer {
        display: flex;
        flex-direction: column;
        flex: 1;
      }
      footer {
        margin-top: auto;
        p {
          margin-bottom: 0;
        }
      }
    }
    img.mediaImage {
      border-radius: 0;
      vertical-align: bottom; /* remove margin underneath image */
    }
    a.cta-button {
      margin: 0;
    }
  }
  .standard { /* card type */
    img.mediaImage {
      width: 100%;
      aspect-ratio: 16 / 9;
      object-fit: cover;
    }
  }
  .icon { /* card type */
    padding: 20px;
    img.mediaImage {
      max-width: 100%; 
    }
    .text-wrapper {
      padding: 20px 0 0;
    }
  }
  .team { /* card type */ 
    padding: 20px;
    img.mediaImage {
      width: 150px;
      min-width: 150px;
      aspect-ratio: 1 / 1;
      object-fit: cover;
      clip-path: circle(50% at 50% 50%);
    }
    .text-wrapper {
      padding: 20px 0 0;
    }
  }
  .text-over-image { /* card type */
    @media(min-width: 768px) {
      position: relative;
      padding: 0;
      .image-wrapper {
        background-color: black;
      }
      img.mediaImage {
        opacity: .75;
        width: 100%;
      }
      .text-wrapper {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 85%;
        max-width: 750px;
        color: white;
      }
      &.light-overlay {
        .image-wrapper {
          background-color: white;
        }
        .text-wrapper {
          color: black;
        }
      }
    }
  }
  .horizontal { /* to be used in conjunction with a card type */
    @media (min-width: 768px) {
      display: flex;
      flex-direction: row;
      text-align: left;
      &.standard { 
        .image-wrapper {
          height: 100%;
          flex: 1;
          min-width: 50%; /* Image and text wrapper exactly half but when space is a squeeze and text might expand out of box allow text to take up space it needs */
          > a {
            height: 100%; /* If a link is added to image in content editor */
          }
        }
        img.mediaImage {
          height: 100%!important;
        }
        .text-wrapper {
          flex: 1;
        }
      }
      &.icon {
        .image-wrapper {
          max-width: 50%;
          flex-shrink: 0;
        }
        .text-wrapper {
          padding: 0 0 0 20px;
        }
      }
      &.team {
        .text-wrapper {
          padding: 0 0 0 20px;
        }
      }
      &.image-on-right { /* to be used in conjunction with .horizontal card */
        flex-direction: row-reverse;
      }
      &:only-child {
        align-items: center;
      }
    }
  }
  .double-width { /* to be used in conjunction with a card type */
    flex-grow: 2!important;
  }
  .triple-width { /* to be used in conjunction with a card type */
    flex-grow: 3!important;
  }
  .no-bg {
    box-shadow: none;
    @media(max-width: 768px) {
      margin-bottom: 0.5em;
    }
    .text-wrapper {
      padding: 0;
    }
  }
  @media (min-width: 768px) {
    .vertical-align-top {
      .text-wrapper {
        height: 100%;
      }
    }
    .vertical-align-middle {
      align-items: center;
    }
  }
  .row-reverse {
    @media(min-width: 768px) {
      flex-direction: row-reverse;
    }
  }
  blockquote { /* revert all bespoke styles that may have been set in Fresco to ensure blockquotes work within templates */
    all: revert;
    &:before, &:after {
      all: revert;
    }
    p {
      all: revert;
      &:before, &:after {
        all: revert;
      }
    }
    margin: 0 0 20px;
    > *:first-child {
      all: revert;
      &:before, &:after {
        all: revert;
      }
      margin-top: 0; 
      &:before {
        opacity: .5;
        content: open-quote; 
        font-size: 50px;
        margin-bottom: -15px;
        display: block;
      }
      &:after {
        content: close-quote;
        color: transparent;
        font-size: 0px;
        height: 0px;
        position: absolute;
        bottom: 0;
        right: 0;
      } 
    }
  }
}
